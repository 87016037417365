<template>
  <v-container>
    <v-app-bar
      fixed
      flat
      height="150"
      class="pt-3"
      style="background-image: linear-gradient(360deg, #dae0e3, #f6f7f7);"
    >
      <v-col>
        <v-row justify="end">
          <v-btn class="mr-n3" icon>
            <v-icon
              :size="icon_size"
              @click="
                (new_imagelist_url = ''),
                  (new_imagelist = ''),
                  (one_image_used = false),
                  (new_file = true)
              "
              >mdi-plus</v-icon
            >
          </v-btn>
          <v-btn class="mr-n4" icon>
            <v-icon :size="icon_size" @click="dialog_search = true"
              >mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn absolute left icon class="ml-6" to="/projects">
            <v-icon :size="icon_size"> mdi-chevron-left </v-icon> Reportes
          </v-btn>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon :size="icon_size">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  modo_ordenar ? update_folder_array() : '',
                    (modo_ordenar = !modo_ordenar)
                "
              >
                <v-list-item-title>Ordenar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="dialog_exportar = true">
                <v-list-item-title>Exportar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>

        <h1
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'text-center text-h4 pt-10'
              : 'text-center text-h3 pt-6'
          "
        >
          Documentos
        </h1>
      </v-col>
    </v-app-bar>
    <br />
    <br />
    <br />
    <br />
    <v-container class="mt-12">
      <div class="text-center" v-if="empty">
        <v-icon class="mx-auto mt-10" style="opacity: 20%;" size="220"
          >mdi-file</v-icon
        >
        <h2 style="opacity: 20%;">No hay nada en tu carpeta</h2>
      </div>
      <v-row>
        <draggable v-model="file_list" class="row wrap" draggable=".item">
          <v-col
            v-for="(j, index) in file_list"
            :key="`${j.name}`"
            cols="4"
            md="2"
            :class="modo_ordenar ? 'item' : ''"
          >
            <div class="text-center">
              <v-icon
                v-touch="{
                  start: () => timer_click_start(),
                  end: () => timer_click_end(),
                }"
                @mousedown="modo_ordenar ? '' : timer_click_start()"
                @mouseup="
                  modo_borrar
                    ? timer_click_end()
                    : (get_file(j.id), timer_click_end())
                "
                :size="folder_size"
                color="blue"
                :class="modo_borrar || modo_ordenar ? 'shake_icon' : 'none'"
                >mdi-file</v-icon
              >
              <v-btn icon absolute>
                <v-icon
                  color="white"
                  size="28"
                  v-if="modo_borrar"
                  @click="(current_file_id = j.id), (delete_file_dialog = true)"
                  class="ml-n16 mt-n2"
                  >mdi-close-circle</v-icon
                >
              </v-btn>
              <h4
                :class="
                  $vuetify.breakpoint.smAndDown ? 'caption ' : 'subtitle-1 '
                "
              >
                {{ j.name }}
              </h4>
            </div>
          </v-col>
        </draggable>
      </v-row>
    </v-container>
    <v-bottom-navigation
      v-if="modo_borrar || modo_ordenar"
      app
      color="teal"
      grow
    >
    </v-bottom-navigation>
    <div class="text-center">
      <v-btn
        dark
        fab
        fixed
        elevation="24"
        bottom
        v-if="modo_borrar"
        @click="modo_borrar = false"
        class="ml-n7 mb-1"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
      <v-btn
        dark
        fab
        fixed
        elevation="24"
        bottom
        v-if="modo_ordenar"
        @click="update_folder_array(), (modo_ordenar = false)"
        class="ml-n7 mb-1"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </div>

    <v-dialog v-model="show_file" width="1000">
      <v-card
        elevation="24"
        style=" border-radius: 12px;"
        class="grey lighten-2"
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-center py-2 pt-4">
              <v-row justify="end">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="delete_file_dialog = true">
                      <v-list-item-title>Eliminar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn absolute left icon class="ml-n3 ">
                  <v-icon size="20" @click="show_file = false"
                    >mdi-close</v-icon
                  >
                </v-btn>
              </v-row>

              <h1
                :class="
                  $vuetify.breakpoint.smAndDown ? 'pb-0' : 'text-h3 mt-n5 mb-n1'
                "
              >
                {{ file_name }}
              </h1>

              <v-img
                v-if="one_image_used"
                class="mx-auto mt-5"
                :lazy-src="new_imagelist_url"
                contain
                :max-width="$vuetify.breakpoint.smAndDown ? 300 : 450"
                :max-height="$vuetify.breakpoint.smAndDown ? 200 : 300"
                :src="new_imagelist_url"
              ></v-img>

              <input
                style="display:none"
                type="file"
                @change="on_img_view"
                ref="newfileInput1"
              />

              <v-btn
                v-if="!one_image_used"
                class="ml-auto mr-2 mt-2"
                @click="$refs.newfileInput1.click()"
                width="60"
                ><v-icon>mdi-image-plus</v-icon></v-btn
              >
              <v-btn
                v-if="one_image_used"
                class="mr-auto mr-auto mt-2"
                width="60"
                @click="
                  (new_imagelist_url = ''),
                    (image_list = ''),
                    (one_image_used = false)
                "
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-col>
          </v-row>

          <v-row justify="center" class="mt-8">
            <v-col cols="md-7">
              <v-form v-model="valid">
                <v-text-field
                  v-model="file_name"
                  label="Name"
                  :rules="reglas"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                style="display:none;"
                  v-model="one_image_used ? image_used1 : image_empty1"
                  label="check"
                  :rules="reglas"
                  required
                ></v-text-field>
                <v-textarea
                  label="Descripción"
                  v-model="file_description"
                  color="teal"
                  :rules="reglas"
                  outlined
                  required
                >
                </v-textarea>
                <v-row justify="center" class="mt-n8 mb-8">
                  <v-checkbox
                    class="mr-5"
                    @click="privado = false"
                    v-model="publico"
                    label="publico"
                  ></v-checkbox>
                  <v-checkbox
                    @click="publico = false"
                    class="ml-5"
                    v-model="privado"
                    label="privado"
                  ></v-checkbox>
                </v-row>
                <div class="text-center">
                  <v-btn class="mr-4" color="error" @click="show_file = false">
                    Cancelar
                  </v-btn>

                  <v-btn
                    :disabled="!valid"
                    class="mr-4"
                    color="blue darken-1"
                    v-on:click="save_file"
                  >
                    Guardar
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>
          <br />
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="new_file" width="1000">
      <v-card
        elevation="24"
        style=" border-radius: 12px;"
        class="grey lighten-2"
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-center py-2 pt-6">
              <v-btn absolute left icon class="ml-n4 mt-n6">
                <v-icon
                  size="20"
                  @click="(new_file = false), (one_image_used = false)"
                  >mdi-close</v-icon
                >
              </v-btn>
              <h1 class="pt-1">Nuevo documento</h1>

              <v-img
                v-if="one_image_used"
                class="mx-auto mt-5"
                :lazy-src="new_imagelist_url"
                contain
                :max-width="$vuetify.breakpoint.smAndDown ? 300 : 450"
                :max-height="$vuetify.breakpoint.smAndDown ? 200 : 300"
                :src="new_imagelist_url"
              ></v-img>

              <input
                style="display:none"
                type="file"
                @change="on_img_selected"
                ref="newfileInput"
              />

              <v-btn
                v-if="!one_image_used"
                class="ml-auto mr-2 mt-2"
                @click="$refs.newfileInput.click()"
                width="60"
                ><v-icon>mdi-image-plus</v-icon></v-btn
              >
              <v-btn
                v-if="one_image_used"
                class="mr-auto ml-auto mt-2"
                width="60"
                @click="
                  (new_imagelist_url = ''),
                    (new_imagelist = ''),
                    (one_image_used = false)
                "
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-8">
            <v-col cols="md-7">
              <v-form v-model="valid">
                <v-text-field
                  v-model="new_file_name"
                  label="Name"
                  :rules="reglas"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                style="display:none;"
                  v-model="one_image_used ? image_used1 : image_empty1"
                  label="check"
                  :rules="reglas"
                  outlined
                  required
                ></v-text-field>

                <v-textarea
                  label="Descripción"
                  v-model="new_file_description"
                  :rules="reglas"
                  color="teal"
                  outlined
                  required
                >
                </v-textarea>
                <v-row justify="center" class="mt-n8 mb-8">
                  <v-checkbox
                    class="mr-5"
                    @click="privado = false"
                    v-model="publico"
                    label="publico"
                  ></v-checkbox>
                  <v-checkbox
                    @click="publico = false"
                    class="ml-5"
                    v-model="privado"
                    label="privado"
                  ></v-checkbox>
                </v-row>
                <div class="text-center">
                  <v-btn class="mr-4" color="error" @click="new_file = false">
                    Cancelar
                  </v-btn>

                  <v-btn
                    :disabled="!valid"
                    class="mr-4"
                    color="blue darken-1"
                    v-on:click="create_file"
                  >
                    Guardar
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>

          <br />
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_file_dialog" width="350">
      <v-card class="text-center" style=" border-radius: 12px;">
        <v-card-title class="text-h4 blue-grey justify-center">
          Eliminar
        </v-card-title>

        <v-card-text class="text-h6">
          ¿Está seguro de que desea eliminar este documento?
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="blue darken-1"
            text
            @click="delete_file_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="error"
            text
            @click="(delete_file_dialog = false), delete_file()"
          >
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_search" width="800">
      <v-card style=" border-radius: 12px;" class="grey lighten-2">
        <v-card-title>
          Buscador
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          class="grey lighten-2"
          :headers="headers"
          :items="file_search_list"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="dialog_search = false"
              v-on:click="get_file(item.id)"
            >
              <v-icon small class="mr-2">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_exportar" width="500">
      <v-card
        elevation="24"
        style=" border-radius: 12px;"
        class="grey lighten-2"
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-center py-2 pt-6">
              <v-btn absolute left icon class="ml-n4 mt-n6">
                <v-icon size="20" @click="dialog_exportar = false"
                  >mdi-close</v-icon
                >
              </v-btn>
              <h1 class="pt-1">Exportar</h1>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-8">
            <v-col cols="10">
              <v-form v-model="valid">
                <v-text-field
                  class="pb-3"
                  v-model="name_exportar"
                  label="Nombre"
                  :rules="reglas"
                  required
                ></v-text-field>

                <div class="text-center">
                  <v-btn
                    v-if="!loading"
                    :disabled="!valid"
                    class="mr-4"
                    color="blue darken-1"
                    @click="loading = true"
                    v-on:click="get_report"
                  >
                    Exportar
                  </v-btn>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-form>
            </v-col>
          </v-row>

          <br />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

/* eslint-disable */
<style scoped>
.shake_icon {
  animation: shake 1s;
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
<script>
import FileSaver from "file-saver";
import axios from "axios";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data: () => ({
    modo_ordenar: false,
    modo_borrar: false,
    search: "",
    select_all_box: false,
    name_exportar: "",
    image_used1: "used",
    image_empty1: "",
    current_id: 0,
    dialog_exportar: false,
    image_list: "",
    file_list: [],
    show_file: false,
    loading: false,
    new_file: false,
    current_folder_info: [],
    dialog_search: false,
    file_search_list: [],
    valid: true,
    reglas: [(v) => !!v || "Campo requerido"],
    file_description: "",
    file_name: "Nombre Documento",
    publico: true,
    one_image_used: false,
    privado: false,
    empty: false,
    original_img: "",
    new_file_name: "",
    new_file_description: "",
    new_imagelist: "",
    new_imagelist_url: "",
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: true,
        value: "name",
      },

      { text: "Editar", value: "actions", sortable: false },
    ],
    delete_file_dialog: false,
    current_file_id: "",
  }),
  created() {
    this.current_id = this.$route.params.id;
    this.parseJwt();
    this.inverval = setInterval(() => this.parseJwt(), 60000);
  },
  mounted() {
    this.get_all_files();
  },
  computed: {
    icon_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 24;
        case "sm":
          return 24;
        case "md":
          return 30;
        case "lg":
          return 30;
        case "xl":
          return 30;
      }
    },
    folder_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 70;
        case "sm":
          return 70;
        case "md":
          return 90;
        case "lg":
          return 90;
        case "xl":
          return 90;
      }
    },
  },
  methods: {
    parseJwt(token = sessionStorage.getItem("token")) {
      if (token == null) {
        window.location.replace("/login");
      }
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      this.auth_user = JSON.parse(jsonPayload);
      var date = new Date();
      var date = date.getTime();
      var date_e = new Date(0);
      date_e.setUTCSeconds(this.auth_user.exp);
      var date_e = date_e.getTime();
      //console.log(date_e);
      let parsed_data = JSON.parse(jsonPayload);
      //console.log(parsed_data);
      this.user_id = parsed_data.userId;

      //console.log(date);
      if (date_e <= date) {
        var session_key = sessionStorage.getItem("session_key");
        axios({
          method: "POST",
          url: process.env.VUE_APP_ROOT_URL + "user/login",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            email: parsed_data.email,
            session_key: session_key,
          },
        })
          .then(function(response) {
            sessionStorage.clear();
            var token = `Bearer ${response.data.token}`;
            var session_key = response.data.session_key;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("session_key", session_key);
          })
          .catch(function(error) {
            sessionStorage.clear();
            window.location.replace("/login");
            console.log(error);
          });
      }
    },
    get_report() {
      // llamada de generar pdf
      let url =
        process.env.VUE_APP_ROOT_URL +
        "file/report/" +
        this.current_id +
        "/" +
        this.name_exportar;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },

        responseType: "arraybuffer",
      })
        .then(function(response) {
          var blob = new Blob([response.data], {
            type: "application/pdf;charset=utf-8",
          });
          FileSaver.saveAs(blob, vueInstance.name_exportar);
          vueInstance.name_exportar = "";
          vueInstance.dialog_exportar = false;
          vueInstance.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    get_all_files() {
      let url =
        process.env.VUE_APP_ROOT_URL + "report_folder/one/" + this.current_id;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          console.log(response);
          vueInstance.current_folder_info = [
            response.data.message.name,
            response.data.message._id,
            response.data.message.public,
          ];
          vueInstance.file_list = [];
          for (let i = 0; i < response.data.message.files.length; i++) {
            if (response.data.message.files[i] != null) {
              vueInstance.file_list.push(response.data.message.files[i]);
              vueInstance.file_search_list.push({
                name: response.data.message.files[i].name,
                id: response.data.message.files[i].id,
              });
            }
          }
          if (vueInstance.file_list < 1) {
            vueInstance.empty = true;
          } else {
            vueInstance.empty = false;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    get_file(id) {
      let url = process.env.VUE_APP_ROOT_URL + "file/one/" + id;
      this.current_file_id = id;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;

      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          console.log("this is the response");
          console.log(response.data);
          vueInstance.file_name = response.data.message.name;
          vueInstance.file_description = response.data.message.description;
          vueInstance.publico = response.data.message.public;
          if (vueInstance.publico == false) {
            vueInstance.privado = true;
          } else {
            vueInstance.privado = false;
          }
          vueInstance.show_file = true;

          vueInstance.new_imagelist_url =
            process.env.VUE_APP_ROOT_URL + response.data.message.image[0].path;

          vueInstance.original_img =
            process.env.VUE_APP_ROOT_URL + response.data.message.image[0].path;
          vueInstance.one_image_used = true;

          vueInstance.image_list = response.data.message.image;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    save_file() {
      for (let i = 0; i < this.file_list.length; i++) {
        if (this.file_list[i].id == this.current_file_id) {
          this.file_list[i].name = this.file_name;
        }
      }

      console.log(this.file_list);
      let url =
        process.env.VUE_APP_ROOT_URL + "file/update/" + this.current_file_id;
      var token = sessionStorage.getItem("token");

      let vueInstance = this;

      if (this.new_imagelist_url != this.original_img) {
        this.update_file_img();
      }
      axios({
        method: "PATCH",
        url: url,
        headers: {
          Authorization: token,

          "Content-Type": "application/json",
        },
        data: {
          name: this.file_name,
          public: this.publico,
          description: this.file_description,
        },
      })
        .then(function(response) {
          console.log(response);
          (vueInstance.show_file = false), vueInstance.update_folder_array();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    update_file_img() {
      var token = sessionStorage.getItem("token");
      const fd = new FormData();
      fd.append("image", this.image_list);

      axios({
        method: "PATCH",
        url:
          process.env.VUE_APP_ROOT_URL +
          "file/update/image/" +
          this.current_file_id,
        headers: {
          Authorization: token,

          "Content-Type": "multipart/form-data",
        },
        data: fd,
      })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    delete_file() {
      for (let i = 0; i < this.file_list.length; i++) {
        if (this.file_list[i].id == this.current_file_id) {
          this.file_list.splice(i, 1);
        }
      }
      console.log(this.file_list);
      let vueInstance = this;
      let url =
        process.env.VUE_APP_ROOT_URL + "file/dump/one/" + this.current_file_id;
      var token = sessionStorage.getItem("token");
      axios({
        method: "DELETE",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }).then(function(response) {
        vueInstance.show_file = false;
        vueInstance.update_folder_array();
        if (vueInstance.file_list < 1) {
          vueInstance.empty = true;
        } else {
          vueInstance.empty = false;
        }
      });
    },
    create_file() {
      let url = process.env.VUE_APP_ROOT_URL + "file/send";
      var token = sessionStorage.getItem("token");
      var vueInstance = this;

      const fd = new FormData();
      fd.append("image", this.new_imagelist);
      fd.append("name", this.new_file_name);
      fd.append("folder", this.current_id);
      fd.append("public", this.publico);
      fd.append("description", this.new_file_description);

      axios({
        method: "POST",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
        data: fd,
      })
        .then(function(response) {
          console.log(response);
          window.location.reload();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    update_folder_array() {
      let url =
        process.env.VUE_APP_ROOT_URL +
        "report_folder/update/" +
        this.current_folder_info[1];
      var token = sessionStorage.getItem("token");
      axios({
        method: "PATCH",
        url: url,
        headers: {
          Authorization: token,

          "Content-Type": "application/json",
        },
        data: {
          name: this.current_folder_info[0],
          public: this.current_folder_info[2],
          files: this.file_list,
        },
      });
    },
    on_img_selected(event) {
      const file = event.target.files[0];
      let url = URL.createObjectURL(file);
      this.new_imagelist_url = url;
      this.new_imagelist = event.target.files[0];
      this.one_image_used = true;
    },
    on_img_view(event) {
      const file = event.target.files[0];
      let url = URL.createObjectURL(file);
      this.new_imagelist_url = url;
      this.image_list = event.target.files[0];
      this.one_image_used = true;
    },

    timer_click_start() {
      this.set_timeout = setTimeout(
        () => (
          console.log("2 SEGUNODS"), (this.modo_borrar = !this.modo_borrar)
        ),
        2000
      );
    },
    timer_click_end() {
      clearTimeout(this.set_timeout);
    },
  },
};
</script>
